import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity';

const BlogContentContainer = ({ sanityPost }: any) => {

    const MyCodeRenderer = (props: any) => {

        return (
            <div className="flex justify-center">
                <pre className="bg-gray-100 p-8 mb-4 mt-4 shadow-lg rounded-lg ">
                    <code className="font-mono text-sm inline bg-grey-lighter px-1">{props.node.code.toString()}</code>
                </pre>
            </div>
        )
    }

    const HighlightRenderer = (props: any) => {
        return <span className="bg-gray-500">{props.children}</span>
    }

    const MainImageRenderer = (props: any) => {
        let assetID: string = props.node.asset._ref;
        const fluidProps = getFluidGatsbyImage(assetID, { maxWidth: 1024 }, { projectId: '8guxnn71', dataset: 'production' })

        return (
            <div className="flex justify-center">
                <div className="overflow-hidden shadow-2xl rounded-lg m-2" style={{ maxHeight: '31vh' }}>
                    <Img fluid={fluidProps} />
                </div>
            </div>
        )
    }


    const BlockRenderer = (props: any) => {
        const { style } = props.node;

        if (/^h\d/.test(style)) {
            const level = style.replace(/[^\d]/g, '')

            switch (level) {
                case '1':
                    return React.createElement(style, { className: `text-6xl mt-12 mb-2` }, props.children)
                case '2':
                    return React.createElement(style, { className: `text-5xl mt-12 mb-2` }, props.children)
                case '3':
                    return React.createElement(style, { className: `text-4xl mt-12 mb-2` }, props.children)
                case '4':
                    return React.createElement(style, { className: `text-3xl mt-12 mb-2` }, props.children)
                case '5':
                    return React.createElement(style, { className: `text-2xl mt-12 mb-2` }, props.children)
                default:
                    return React.createElement(style, { className: `text-xl mt-12 mb-2` }, props.children)
            }
        }

        if (style === 'blockquote') {
            return <div className="flex justify-center">
                <blockquote className="text-sm text-center p-2 italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote mb-12">"{props.children}"</blockquote>
            </div>
        }

        if (style === 'normal') {
            return <p className="p-6">{props.children}</p>
        }

        // Fall back to default handling
        return (
            <div className="flex justify-center">
                {BlockContent.defaultSerializers.types.block(props)}
            </div>
        )
    }

    const serializer = {
        types: {
            block: BlockRenderer,
            myCode: MyCodeRenderer,
            mainImage: MainImageRenderer,
        },
        marks: {
            highlight: HighlightRenderer
        },
        renderContainerOnSingleChild: ((props: any) => { return (<hr />) }),
        hardBreak: false
    }

    return (
        <div className="flex flex-col mx-auto p-5">
            <div className="flex justify-center mb-4">
                <div className="text-6xl text-center text-black">
                    <p>{sanityPost.title}</p>
                </div>
            </div>
            <div className="flex mb-4">
                <div className="mx-auto text-md text-right text-black">
                    <div className="mt-4">
                        <p> Published: {new Date(sanityPost.publishedAt).toLocaleDateString()} </p>
                        <p> Author: {sanityPost.authors[0].author.name} </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-grow justify-center mb-4 shadow-lg p-12">
                <div className="container text-black font-mono leading-loose tracking-wide text-2xl">
                    <BlockContent serializers={serializer} blocks={sanityPost._rawBody} />
                </div>
            </div>
        </div>
    )
}

export default BlogContentContainer;
